@tailwind base;
@tailwind components;
@tailwind utilities; 

body {
    cursor: none !important;
    background-color: #02060F
}

html {
    transition: 0.3s;
    cursor: none !important;
    --primary: #5d5cda;
    --primary-100: rgba(93, 92, 218, .1);
    --primary-200: rgba(93, 92, 218, .2);
    --primary-300: rgba(93, 92, 218, .3);
    --primary-400: rgba(93, 92, 218, .4);
    --primary-500: rgba(93, 92, 218, .5);
    --primary-600: rgba(93, 92, 218, .6);
    --primary-700: rgba(93, 92, 218, .7);
    --primary-800: rgba(93, 92, 218, .8);
    --primary-900: rgba(93, 92, 218, .9);    
}

.cursorBorder {

    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid var(--primary);
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
    background-color: rgba(0,0,0,0.40)

}
  
.main {

    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--primary);
    opacity: .3;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;

}

.main.click {
    width: 15px;
    height: 15px;
    opacity: .9
}

.cursorBorder.click {
    width: 50px;
    height: 50px;
    background-color: rgba(0,0,0,0.40)
}

::selection {
    background-color: var(--primary-300)
}

.shadowed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(at 75% 0%,var(--primary) 0,transparent 33%),radial-gradient(at 25% 0,var(--primary) 0,transparent 0),radial-gradient(at 10% 10%,var(--primary) 0,transparent 33%);
    filter: blur(45px);
    opacity: 15%;
    z-index: -1;
}

.wrap {
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    height:100vh;
}
  
.wrapper {
    height: auto;
    display: block;
    padding-bottom: 3rem
}

.phoneNav {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    height: 100vh;
    width: 100%
}

.spin {
    -webkit-animation: spin 0.8s linear infinite; /* Safari */
    animation: loading 0.8s linear infinite;
}
  
@-webkit-keyframes loading {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}